/* @import '../../../styles/propertySets.css'; */

.root {
  position: relative;
  display: inline-block;
}

.label {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  /* padding: var(--marketplaceButtonSmallDesktopPadding); */
  border: 1px solid var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  background-color: var(--colorWhite);
  height: auto;
  min-height: 0;
  padding: var(--marketplaceButtonSmallDesktopPadding);
  margin: 0;
  font-weight: var(--fontWeightMedium);
  font-size: 13px;
  line-height: 20px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  border: 1px solid var(--marketplaceColor);
  border-radius: var(--borderRadiusMedium);
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);
  height: auto;
  min-height: 0;
  padding: var(--marketplaceButtonSmallDesktopPadding);
  margin: 0;
  font-weight: var(--fontWeightMedium);
  font-size: 13px;
  line-height: 20px;
  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}
